import axios from 'axios';
import CONFIG from 'config';
import FILTER_CONFIG from 'config-filters';

import { queryWithSearchParamsOnly } from 'utils/string-mapper/string-mapper';
import { setAppliedFilters, formatFilters } from 'utils/filters/filters';

export const name = 'knowledgeAi';

export const CHANGE_SEARCH_TERM = 'CHANGE_SEARCH_TERM';
export const AI_SEARCH_PENDING = 'AI_SEARCH_PENDING';
export const AI_SEARCH_FAILURE = 'AI_SEARCH_FAILURE';
export const AI_SEARCH_SUCCESS = 'AI_SEARCH_SUCCESS';
export const SET_AI_SEARCH_PENDING = 'SET_AI_SEARCH_PENDING';
export const SET_AI_SEARCH_FAILED = 'SET_AI_SEARCH_FAILED';
export const SET_AI_APPLIED_FILTERS_PENDING = 'SET_AI_APPLIED_FILTERS_PENDING';
export const SET_AI_APPLIED_FILTERS_SUCCESS = 'SET_AI_APPLIED_FILTERS_SUCCESS';
export const SET_AI_APPLIED_FILTERS_FAILED = 'SET_AI_APPLIED_FILTERS_FAILED';
export const SET_AI_ACTIVE_FILTER_INDEX = 'SET_AI_ACTIVE_FILTER_INDEX';
export const SET_AI_SEARCH_SUCCESS = 'SET_AI_SEARCH_SUCCESS';
export const API_SEARCH_CANCELLED = 'API_SEARCH_CANCELLED';
export const KNOWLEDGE_AI_SET_SHOW_CHATBOT = 'KNOWLEDGE_AI_SET_SHOW_CHATBOT';
export const TOGGLE_AND_FILTER = 'TOGGLE_AND_FILTER';
export const APPLY_AND_FILTERS = 'APPLY_AND_FILTERS';
export const CLEAR_PENDING_AND_FILTERS = 'CLEAR_PENDING_AND_FILTERS';

const sources = [];
const initialState = {
  loading: false,
  loadMorePending: false,
  error: false,
  errorMessage: '',
  empty: false,
  previousSearch: { query: {} },
  lastSearch: { query: {} },
  results: [],
  totalCount: 0,
  pageCount: 0,
  currentPage: CONFIG.DEFAULT_CURRENT_PAGE,
  filters: [],
  appliedQueryParams: {}, // object ready for qs to convert to a string in string-mapper.js
  appliedFilters: {}, // object ready for qs to convert to a string in string-mapper.js
  activeFilter: '',
  query: queryWithSearchParamsOnly(),
  queryIntents: [],
  qiApplied: false,
  qiOriginal: '',
  autoCorrectMode: true,
  autoCorrect: {
    isSearchTermCorrected: false,
    correctedSearchTerm: ''
  },
  andFilters: {},
  pendingAndFilters: {},
  showChatbot: false,
};

// using for AI view as AI content is prod only
export const qaKpCmsIds = ['454f6d50-7cbc-4994-84df-ee4dcc633494', '454f6d50-7cbc-4994-84df-ee4dcc633494', '454f6d50-7cbc-4994-84df-ee4dcc633494', '8f75ae03-9dcb-4f77-8da1-851d22d265f4', 'ac947bc5-7524-4443-8bc9-2bfe94ea897f', 'bd4ec98e-d44e-4628-adae-0de1467c6330', '4d2c8f12-d29f-4579-9e9a-846dad949664', 'aecc8528-341e-421f-b5d8-1934f44b87fa', 'f5aabd10-37dc-4c56-b875-8a19c1157ef2', 'bb1bc21f-48b1-47d4-89b4-f249f9d821b3', 'c1af3cba-1f11-4fc8-a449-580359089818', '86e02c60-0e38-4e5c-a7b3-92557897eca0', '6012fc75-9360-495f-98db-7160a1985ea9', 'ec3784fb-ea02-4b15-ac28-0bf893d2e955', '65f996c3-02ee-4fe8-9420-0a76b518dcc0', '794d091d-6086-4162-b4b5-2e14f56ac9b3', '1c026a0b-fa72-4cae-90ef-1978afa3cb9a', '9dd2f048-2707-4555-b048-e7e2628692d5', '48e9954d-83a8-49de-80f9-8ab01ffa4392', '7dede564-602c-4616-947b-47463e7bd29d', '7b4b0e91-e04b-4c03-acf3-4a17a3f349e7'];

const aiSearch = (appliedQueryParams, aiViewFilterParams) => async (dispatch) => {
  const newSource = axios.CancelToken.source();
  sources.push(newSource);

  const top_n = CONFIG.SEARCH.GENAI_RETRIEVAL_TOP_N;
  const searchQuery = appliedQueryParams?.query;
  const method = 'hybrid_1';

  dispatch({ type: AI_SEARCH_PENDING, payload: searchQuery });
  try {
    const appliedDateFilter = aiViewFilterParams?.appliedFilters?.dateRevised;
    const documentLevelSearchFilter = aiViewFilterParams?.appliedFilters?.documentLevel?.[0]?.CHECKBOX_ONLY_FILTER ?? false;
    const appliedIndustryPAFilters = aiViewFilterParams?.appliedFilters?.industryPA;
    const appliedFunctionPAFilters = aiViewFilterParams?.appliedFilters?.functionalPA;
    const appliedSubjectPAFilters = aiViewFilterParams?.appliedFilters?.subjectPA;
    const appliedAuthorFilters = aiViewFilterParams?.appliedFilters?.author;
    const appliedMaterialFilters = aiViewFilterParams?.appliedFilters?.materialType;
    const appliedDocFormatFilters = aiViewFilterParams?.appliedFilters?.documentFormat;
    const dateRevised = appliedDateFilter ? appliedDateFilter[0].name : null;
    const industryList = appliedIndustryPAFilters ? appliedIndustryPAFilters.map((f) => f.name) : null;
    const functionList = appliedFunctionPAFilters ? appliedFunctionPAFilters.map((f) => f.name) : null;
    const authorList = appliedAuthorFilters ? appliedAuthorFilters.map((f) => f.name) : null;
    const materialList = appliedMaterialFilters ? appliedMaterialFilters.map((f) => f.name) : null;
    const filetypeList = appliedDocFormatFilters ? appliedDocFormatFilters.map((f) => f.name.substring(1)) : null;
    const subjectList = appliedSubjectPAFilters ? appliedSubjectPAFilters.map((f) => {
      if (f?.name && f.name.includes('/')) {
        let toks = f.name.split('/');
        if (toks[toks.length - 1].length === 0) {
          return toks[toks.length - 2];
        }
        return toks[toks.length - 1];
      }
      else return f.name;
    }) : null;

    const baseBody = {
      query: searchQuery,
      method,
      top_n,
      date_filter: dateRevised,
      content_type: materialList,
      subject: subjectList,
      ipa: industryList,
      fpa: functionList,
      authors: authorList,
      file_format: filetypeList
    };

    const body = documentLevelSearchFilter ? { ...baseBody, top_vector_per_doc: true } : baseBody;

    const data = await axios.post(CONFIG.API_URL.GENAI_RETRIEVAL,
      body,
      {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );

    const results = data.results?.map((result, i) => {
      const dateRevised = result?.date ? new Date(`${result.date}T00:00:00`) : null;

      let kpCmsId = result.kp_cms_id;
      if (CONFIG.ENVIRONMENT !== 'prod' && CONFIG.ENVIRONMENT !== 'stg' && i < qaKpCmsIds.length) {
        kpCmsId = qaKpCmsIds[i];
      }
      const newResult = {
        ...result,
        id: kpCmsId,
        docId: kpCmsId,
        kbCmsId: '',
        kpCmsId: kpCmsId,
        docRank: result.rank,
        docType: result.material_type,
        fileName: result.filename,
        materialDesc: result.desc,
        resultType: '',
        materialUrl: '',
        dateRevised: dateRevised,
        globalRelevance: result.relevance,
        materialId: 0,
        hasHtmlPreview: true,
        hasImagePreview: true,
        relevantSlidePath: result.image_path,
        paRecommended: false,
        openAccessInd: true,
        allSubjects: result.subject_json ? JSON.parse(`[${result.subject_json}]`).map((subject) => ({
          attachmentId: '',
          fullPath: subject.path?.replace(/>/g, '/') || '',
          hierarchyLevel: 0,
          kpCmsId: '',
          parentId: subject.Parent_ID || '',
          subjectId: subject.SUBJECT_ID || '',
          subjectName: subject.SUBJECT_NAME || '',
          urlId: '',
        })) : [],
        allAuthors: result.author_json ? JSON.parse(`[${result.author_json}]`).map((author) => ({
          additionalContact: (author.IsAddtionalContact === 'Y'),
          email: author.Email || '',
          firstName: author.firstname || '',
          hrEmployeeId: author.HR_emp_id || '',
          lastName: author.lastname || '',
          role: '',
          roleSortOrder: author.positionSortOrder || 0,
          staffId: 0,
        })) : [],
        functionalAllPAs: result.fpa_json ? JSON.parse(`[${result.fpa_json}]`).map((fpa) => ({
          fullPath: fpa.path?.replace(/>/g, '/') || '',
          hiearchyLevel: 0,
          keywordTopic: fpa.keywordTopic || '',
          owner: (fpa.isOwner === 'true'),
          paRecommended: fpa.ISPARECOMMENDED || false,
          parentId: fpa.Parent_ID || '',
          topicId: fpa.Topic_ID || '',
          topicNameAlias: fpa.Topic_Name_Alias || '',
        })) : [],
        industryAllPAs: result.ipa_json ? JSON.parse(`[${result.ipa_json}]`).map((ipa) => ({
          fullPath: ipa.path?.replace(/>/g, '/') || '',
          hiearchyLevel: 0,
          keywordTopic: ipa.keywordTopic || '',
          owner: (ipa.isOwner === 'true'),
          paRecommended: ipa.ISPARECOMMENDED || false,
          parentId: ipa.Parent_ID || '',
          topicId: ipa.Topic_ID || '',
          topicNameAlias: ipa.Topic_Name_Alias || '',
        })) : [],
      };

      delete newResult.kp_cms_id;
      delete newResult.filename;
      delete newResult.rank;
      delete newResult.material_type;
      delete newResult.date;
      delete newResult.relevance;
      delete newResult.author_json;
      delete newResult.subject_json;
      delete newResult.fpa_json;
      delete newResult.ipa_json;

      return newResult;
    });

    const refiners = convertAIFiltersToRefiners(results);
    const filtersFromAPIAndConfig = formatFilters(refiners, FILTER_CONFIG.KNOWLEDGE_FILTERS.FILTER_BAR, true);
    //  At the moment trees not supported
    filtersFromAPIAndConfig.forEach((filter) => {
      filter.TREE_STRUCTURE = false;
      filter.FULL_TREE = false;
    });

    const payload = {
      ...data,
      query: appliedQueryParams,
      results,
      autoCorrect: {
        isSearchTermCorrected: false,
        correctedSearchTerm: ''
      },
      filters: filtersFromAPIAndConfig
    };

    dispatch({
      type: AI_SEARCH_SUCCESS,
      payload
    });
  } catch (error) {
    if (error.cancelled) return;
    dispatch({ type: AI_SEARCH_FAILURE, payload: error.toString() });
    throw error;
  }
};

const convertAIFiltersToRefiners = (results) => {
  const industryRefinerVals = {};
  const functionRefinerVals = {};
  const subjectRefinerVals = {};
  const authorRefinerVals = {};
  const materialTypeRefinerVals = {};
  const fileFormatRefinerVals = {};
  const dateRefinerVals = {};
  const dateOptions = FILTER_CONFIG.KNOWLEDGE_FILTERS.FILTER_BAR.DOCUPDATEDDATERANGE.options;

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  results.forEach((result) => {
    const { ipa = [], fpa = [], subject = [], authors = [], docType, fileName, dateRevised } = result;
    ipa.forEach((industry) => {
      industryRefinerVals[industry] = { count: ++industryRefinerVals[industry] || 1 };
    });
    fpa.forEach((functionPA) => {
      functionRefinerVals[functionPA] = { count: ++functionRefinerVals[functionPA] || 1 };
    });
    subject.forEach((subject) => {
      subjectRefinerVals[subject] = { count: ++subjectRefinerVals[subject] || 1 };
    });
    authors.forEach((author) => {
      authorRefinerVals[author] = { count: ++authorRefinerVals[author] || 1 };
    });
    if (docType) {
      materialTypeRefinerVals[docType] = { count: ++materialTypeRefinerVals[docType] || 1 };
    }
    if (fileName) {
      const rindex = fileName.lastIndexOf('.');
      if (rindex > 0) {
        const ext = fileName.substring(rindex).toLowerCase();
        fileFormatRefinerVals[ext] = { count: ++fileFormatRefinerVals[ext] || 1 };
      }
    }
    if (dateRevised) {
      const diffDays = Math.floor(Math.abs(today - dateRevised) / (24 * 60 * 60 * 1000));
      const diffYears = today.getFullYear() - dateRevised.getFullYear();
      const diffMonths = (diffYears * 12) + (today.getMonth() - dateRevised.getMonth());

      let dateOption = null;
      let sortOption = null;
      if (diffDays <= 30) {
        dateOption = dateOptions[5];
        sortOption = 5;
      }
      else if (diffDays <= 90) {
        dateOption = dateOptions[4];
        sortOption = 4;
      }
      else if (diffMonths <= 6) {
        dateOption = dateOptions[3];
        sortOption = 3;
      }
      else if (diffMonths <= 12) {
        dateOption = dateOptions[2];
        sortOption = 2;
      }
      else if (diffYears <= 2) {
        dateOption = dateOptions[1];
        sortOption = 1;
      }
      else if (diffYears <= 5) {
        dateOption = dateOptions[0];
        sortOption = 0;
      }
      if (dateOption) {
        dateRefinerVals[dateOption.display] = {
          count: ++dateRefinerVals[dateOption.display] || 1,
          sortOrder: sortOption
        };
      }
    }
  });

  const alphaSort = (ref1, ref2) => ref1.display.localeCompare(ref2.display);
  const dateSort = (ref1, ref2) => parseInt(ref1.sortOrder) - parseInt(ref2.sortOrder);

  return [
    getRefiner(FILTER_CONFIG.KNOWLEDGE_FILTERS.FILTER_BAR.INDUSTRYPATOPIC.SINEQUA_PARAMETER_NAME, industryRefinerVals, alphaSort),
    getRefiner(FILTER_CONFIG.KNOWLEDGE_FILTERS.FILTER_BAR.FUNCTIONALPATOPIC.SINEQUA_PARAMETER_NAME, functionRefinerVals, alphaSort),
    getRefiner(FILTER_CONFIG.KNOWLEDGE_FILTERS.FILTER_BAR.SUBJECTPAS.SINEQUA_PARAMETER_NAME, subjectRefinerVals, alphaSort),
    getRefiner(FILTER_CONFIG.KNOWLEDGE_FILTERS.FILTER_BAR.BCGAUTHORS.SINEQUA_PARAMETER_NAME, authorRefinerVals, alphaSort),
    getRefiner(FILTER_CONFIG.KNOWLEDGE_FILTERS.FILTER_BAR.MATERIALTYPE.SINEQUA_PARAMETER_NAME, materialTypeRefinerVals, alphaSort),
    getRefiner(FILTER_CONFIG.KNOWLEDGE_FILTERS.FILTER_BAR.FILE_FORMAT.SINEQUA_PARAMETER_NAME, fileFormatRefinerVals, alphaSort),
    getRefiner(FILTER_CONFIG.KNOWLEDGE_FILTERS.FILTER_BAR.DOCUPDATEDDATERANGE.SINEQUA_PARAMETER_NAME, dateRefinerVals, dateSort)
  ];
};

const getRefiner = (parameterName, refinerVals, sortFn) => {
  return {
    refinerType: 'List',
    parameterName: parameterName,
    refinerValues: Object.keys(refinerVals).map((val) => {
      return {
        display: val,
        count: refinerVals[val].count,
        children: null,
        sortOrder: refinerVals[val]?.sortOrder
      };
    }).sort(sortFn)
  };
};

export const actions = {
  aiSearch,
  setActiveFilter: (filterName) => async (dispatch) => dispatch({ type: SET_AI_ACTIVE_FILTER_INDEX, payload: filterName }),
  setAppliedFilters: (queryParams) => setAppliedFilters(queryParams, FILTER_CONFIG.KNOWLEDGE_FILTERS.FILTER_BAR, SET_AI_APPLIED_FILTERS_PENDING, SET_AI_APPLIED_FILTERS_SUCCESS, SET_AI_APPLIED_FILTERS_FAILED),
  cancel: () => async (dispatch) => {
    sources.forEach((source) => source.cancel());
    dispatch({ type: API_SEARCH_CANCELLED });
  },
  setShowChatbot: (showChatbot) => (dispatch) => dispatch({ type: KNOWLEDGE_AI_SET_SHOW_CHATBOT, payload: showChatbot }),
  toggleAndFilter: (payload) => async (dispatch) => dispatch({ type: TOGGLE_AND_FILTER, payload }),
  applyAndFilters: () => async (dispatch) => dispatch({ type: APPLY_AND_FILTERS }),
  clearPendingAndFilters: () => async (dispatch) => dispatch({ type: CLEAR_PENDING_AND_FILTERS })
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_SEARCH_TERM: // dispatched from type-ahead
      return {
        ...state,
        query: {
          ...state.query,
          [CONFIG.QUERY_PARAMS.QUERY]: action.payload
        }
      };
    case AI_SEARCH_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
        empty: false,
        previousSearch: state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload?.query,
            emptyQuery: !action.payload?.query?.query?.trim().length
          }
        }
      };
    case AI_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case AI_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        empty: action.payload.results.length <= 0,
        results: action.payload.results,
        totalCount: action.payload.results.length <= 0 ? 0 : action.payload.results.length,
        currentPage: action.payload.CurrentPage,
        pageCount: action.payload.PageCount || 0,
        filters: action.payload.filters || [],
        paRecommendedFilter: action.payload.paRecommendedFilter,
        previousSearch: state.previousSearch.query?.query ? state.previousSearch : state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        },
        queryIntents: action.payload.queryIntents || [],
        qiApplied: false,
        autoCorrect: action.payload.autoCorrect
      };
    case SET_AI_SEARCH_PENDING:
      return {
        ...state,
        appliedFilters: {}
      };
    case SET_AI_SEARCH_FAILED:
      return {
        ...state,
        appliedFiltersError: true,
        appliedFiltersErrorMsg: action.payload.errorMessage
      };
    case SET_AI_APPLIED_FILTERS_PENDING:
      return {
        ...state,
        appliedFilters: {}
      };
    case SET_AI_APPLIED_FILTERS_FAILED:
      return {
        ...state,
        appliedFiltersError: true,
        appliedFiltersErrorMsg: action.payload.errorMessage
      };
    case SET_AI_APPLIED_FILTERS_SUCCESS:
      return {
        ...state,
        appliedFilters: action.payload.appliedFilters,
        appliedQueryParams: action.payload.appliedQueryParams,
        appliedFilterAndQueryParams: action.payload.appliedFilterAndQueryParams
      };
    case SET_AI_ACTIVE_FILTER_INDEX:
      return {
        ...state,
        activeFilter: action.payload
      };
    case SET_AI_SEARCH_SUCCESS:
      return {
        ...state,
        appliedFilters: action.payload.appliedFilters,
        appliedQueryParams: action.payload.appliedQueryParams,
        appliedFilterAndQueryParams: action.payload.appliedFilterAndQueryParams
      };
    case API_SEARCH_CANCELLED:
      return {
        ...state,
        loading: false,
        results: [],
        totalCount: 0
      };
    case KNOWLEDGE_AI_SET_SHOW_CHATBOT:
      return {
        ...state,
        showChatbot: action.payload
      };
    case TOGGLE_AND_FILTER:
      const andFilterKey = action.payload;
      if (andFilterKey === undefined) {
        return state;
      }

      const pendingExists = Object.keys(state.pendingAndFilters).includes(andFilterKey);
      const andState = state.andFilters[andFilterKey];
      const toggled = pendingExists ? !state.pendingAndFilters[andFilterKey] : !andState;

      return {
        ...state,
        pendingAndFilters: {
          ...state.pendingAndFilters,
          [andFilterKey]: toggled
        }
      };
    case APPLY_AND_FILTERS:
      return {
        ...state,
        andFilters: { ...state.pendingAndFilters },
        pendingAndFilters: {}
      };
    case CLEAR_PENDING_AND_FILTERS:
      return {
        ...state,
        pendingAndFilters: {}
      };
    default:
      return state;
  }
}